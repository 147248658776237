import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  ThemeProvider,
  styled,
  Box,
} from "@mui/material";
import LogInPopUp from "../LogInPopUp/LogInPopUp";
import TVLButton from "../TVLButton/TVLButton";
import { longDialog_theme } from "../../../theme/longDialog";

interface SessionPopupProps {
  open: boolean;
  onClose: () => void;
  onLogout: () => void;
  message: string;
}

const StyledDialog = styled(Dialog)({
  "& .MuiDialogTitle-root": {
    fontFamily: "Kanit",
    fontSize: "24px",
    fontWeight: "500",
    color: "#000000",
  },
});

const SessionPopup: React.FC<SessionPopupProps> = ({
  open,
  onClose,
  onLogout,
  message,
}) => {
  const [openLogInPopUp, setOpenLogInPopUp] = useState(false);

  return (
    <ThemeProvider theme={longDialog_theme}>
      <StyledDialog open={open} onClose={onClose}>
        <LogInPopUp
          open={openLogInPopUp}
          setOpen={setOpenLogInPopUp}
          redirect_uri={window.location.pathname}
        />

        <DialogTitle>แจ้งเตือนจากระบบ</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="bodyText"
            sx={{ fontSize: "16px", fontWeight: "400", fontFamily: "Kanit" }}
          >
            {message}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <TVLButton
              onClick={() => setOpenLogInPopUp(true)}
              color="primary"
              size="small"
            >
              ลงชื่อเข้าสู่ระบบใหม่อีกครั้ง
            </TVLButton>
            <TVLButton onClick={onLogout} color="secondary" size="small">
              ออกจากระบบ
            </TVLButton>
          </Box>
        </DialogActions>
      </StyledDialog>
    </ThemeProvider>
  );
};

export default SessionPopup;
