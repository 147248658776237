import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
// import jwt from "jsonwebtoken";
const { decodeJwt } = require("jose");

import { getAccessToken, getOrgType } from "../../../services/data-connector";

import { AccountInfo } from "../../../interfaces/AccountInfo";
import { RootState } from "../../store";

interface AuthState {
  access_token: string;
  orgType: string | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string;
  expiresAt: number;
}

const initialState: AuthState = {
  access_token: "",
  orgType: null,
  status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
  error: "",
  expiresAt: 0,
};


export type FetchAccessTokenError = "expired" | "invalid" | "error";

export const fetchAccessToken = createAsyncThunk<
  string,
  void,
  { rejectValue: FetchAccessTokenError }
>(
  "authentication/fetchAccessToken",

  async (_, { rejectWithValue }) => {
    try {
      let token_list = document.cookie
        .split(";")
        .filter((string) => string.includes("token="));
      let token = "";
      //check if logged in
      if (token_list.length != 0) {
        token = token_list[0].replace("token=", "").trim();
      }
      const { res, status } = await getAccessToken(token);
      if (status === 200) {
        let access_token = res.access_token;
        if (res.hasOwnProperty("refresh_token")) {
          let refresh_token = res.refresh_token;
          Cookies.set("token", refresh_token, { expires: 60 });
          window.location.reload()
        }
        return access_token;
      } else {
        // More specific error handling
        if (status === 401) {
          if (res.detail === "Token has expired")
            return rejectWithValue("expired");
          else if (res.detail === "Token invalid")
            return rejectWithValue("invalid");
          else return rejectWithValue("error");
        }

        return rejectWithValue("error");
      }
    } catch (err) {
      return rejectWithValue("error");
    }
  }
);

export const fetchOrgType = createAsyncThunk<
  string,
  string,
  { rejectValue: string }
>(
  "authentication/fetchOrgType",
  async (accessToken: string, { rejectWithValue }) => {
    try {
      const { res, status } = await getOrgType(accessToken);
      if (status === 200) {

        return res.orgType;
      } else {
        return rejectWithValue("error");
      }
    } catch (err) {
      return rejectWithValue("error");
    }
  }
);



export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    access_tokenAdded(state, action) {
      state.access_token = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAccessToken.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAccessToken.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Adding date and reactions
        state.access_token = action.payload;
        const decodedToken = decodeJwt(action.payload) as AccountInfo;

        if (decodedToken && decodedToken.exp) {
          state.expiresAt = decodedToken.exp;
        }
      })
      .addCase(fetchAccessToken.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ? action.error.message : "";
      })
      .addCase(fetchOrgType.fulfilled, (state, action) => {
        state.orgType = action.payload;
      });

  },
});

export const selectAccessToken = (state: RootState) =>
  state.authentication.access_token;

export const selectAccessTokenStatus = (state: RootState) =>
  state.authentication.status;

export const selectAccessTokenError = (state: RootState) =>
  state.authentication.error;

export const selectExpirationAt = (state: RootState) =>
  state.authentication.expiresAt;

export const selectOrgType = (state: RootState) =>
  state.authentication.orgType;

export const { access_tokenAdded } = authenticationSlice.actions;

export default authenticationSlice.reducer;
