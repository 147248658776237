import "./App.css";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Footer from "./pages/Footer/Footer";
import Mission from "./pages/Mission/Mission";
import ContactUs from "./pages/ContactUs/ContactUs";
import Policies from "./pages/Policies/Policies";
import EmailVerification from "./pages/EmailVerification/EmailVerification";
import CookiesPopup from "./components/shared/CookiesPopup/CookiesPopup";
import Privilege from "./pages/Privilege/Privilege";
import FAQ from "./pages/FAQ/FAQ";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Registration from "./pages/Registration/Registration";
import MOURegister from "./pages/MOUResgister/MOURegister";
import RegisterMemberTVL from "./pages/RegisterMemberTVL/RegisterMemberTVL";
import RegisterMemberPlatform from "./pages/RegisterMemberPlatform/RegisterMemberPlatform";
import RegisterMemberMOU from "./pages/RegisterMemberMOU/RegisterMemberMOU";
import RegisterMemberAllianceTVL from "./pages/RegisterMemberAllianceTVL/RegisterMemberAllianceTVL";
import RegisterMemberAlliancePlatform from "./pages/RegisterMemberAlliancePlatform/RegisterMemberAlliancePlatform";
import RegisterPublicOrg from "./pages/RegisterPublicOrg/RegisterPublicOrg";
import Infographic from "./pages/Infographic/Infographic";
import Dashboard from "./pages/Dashboard/Dashboard";
import InfographicDetail from "./pages/InfographicDetail/InfographicDetail";
import { useCallback, useEffect, useState } from "react";
import Forbidden from "./pages/Forbidden/Forbidden";
import { createBrowserHistory, History } from "history";
import { Switch, Route, Router } from "react-router-dom";
import Unauthorized from "./pages/Unauthorized/Unauthorized";
import {
  selectAccessToken,
  fetchAccessToken,
  selectAccessTokenStatus,
  selectAccessTokenError,
  selectExpirationAt,
  FetchAccessTokenError,
} from "./redux/features/authentication/authenticationSlice";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "./redux/store";
import Navbar2 from "./pages/Navbar/Navbar";
import { getUserAccount } from "./utils/login";
import DashboardPreview from "./pages/DashboardPreview/DashboardPreview";
import BookmarkPage from "./pages/BookmarkPage/BookmarkPage";
import { PaletteColorOptions } from "@mui/material";
import SessionPopup from "./components/shared/SessionPopup/SessionPopup";
import Cookies from "js-cookie";
import { STATUS } from "./variable/enum";

const hist: History = createBrowserHistory();

declare module "@mui/material/styles" {
  interface TypographyVariants {
    title: React.CSSProperties;
    ahref: React.CSSProperties;
    bodyText: React.CSSProperties;
    formHelperText: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
    ahref?: React.CSSProperties;
    bodyText?: React.CSSProperties;
    formHelperText?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    title: true;
    ahref: true;
    bodyText: true;
    formHelperText: true;
  }
}
declare module "@mui/material/styles" {
  interface CustomPalette {
    tertiary?: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

function App() {
  // the stored value is of string form, hence needed to parse by JSON first
  //
  const [cookiesPopupState, setCookiesPopupState] = useState<boolean>(
    !(JSON.parse(window.localStorage.getItem("cookiesAccept")!) === false)
  );

  // set value in session storage
  // the stored value is of string form.
  useEffect(() => {
    window.localStorage.setItem("cookiesAccept", cookiesPopupState.toString());
  });
  const accessToken = useSelector(selectAccessToken);
  const dispatch = useDispatch<AppDispatch>();
  // const dispatch = useDispatch()
  const accessTokenStatus = useSelector(selectAccessTokenStatus);
  const accessTokenError = useSelector(selectAccessTokenError);
  const expiresAt = useSelector(selectExpirationAt);
  const [showSessionPopup, setShowSessionPopup] = useState(false);
  const [sessionMessage, setSessionMessage] = useState("");
  const [tokenFetchFailed, setTokenFetchFailed] = useState(false);


  const user = getUserAccount();

  function isAccessTokenExpired() {
    return Date.now() >= expiresAt;
  }

  const handleLogOut = useCallback(() => {
    Cookies.remove("token");
    if (window.location.search) {
      // remove ?from=register
      if (window.location.search.includes("from")) {
        window.location.href = window.location.pathname;
      } else {
        window.location.reload();
      }
    } else {
      window.location.reload();
    }
  }, []);

  // Add effect to check token validity
  useEffect(() => {
    const handleInvalidToken = () => {
      setSessionMessage(
        "เซสชันของคุณไม่ถูกต้องหรือหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
      );
      setShowSessionPopup(true);
    };

    // Check initial token validity
    if (document.cookie.includes("token=") && !user.getIsLoggedIn()) {
      handleInvalidToken();
    }
  }, []);

  // Existing token refresh effect
  useEffect(() => {
    // Skip if we already know token fetch failed
    if (tokenFetchFailed) return;

    try {
      if (
        (!accessToken || isAccessTokenExpired()) &&
        user.getIsLoggedIn() &&
        accessTokenStatus === STATUS.IDLE
      ) {
        dispatch(fetchAccessToken())
          .unwrap()
          .catch((error: FetchAccessTokenError) => {
            setTokenFetchFailed(true); // Prevent further attempts
            if (error === "expired")
              setSessionMessage(
                "เซสชันของคุณหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
              );
            else if (error === "invalid")
              setSessionMessage(
                "เซสชันของคุณไม่ถูกต้อง กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
              );
            else
              setSessionMessage(
                "เกิดข้อผิดพลาดในการเชื่อมต่อ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
              );
            setShowSessionPopup(true);
          });
      }
    } catch (error) {
      setTokenFetchFailed(true); // Prevent further attempts
      setSessionMessage(
        "เกิดข้อผิดพลาดในการเชื่อมต่อ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
      );
      setShowSessionPopup(true);
    }
  }, [accessToken, dispatch, isAccessTokenExpired]); // Removed user from dependencies

  // Reset token fetch failed state when user logs in again
  useEffect(() => {
    if (accessToken) {
      setTokenFetchFailed(false);
    }
  }, [accessToken]);

  return (
    <div className="App">
      <Router history={hist}>
        <Navbar2 />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about-us" component={Mission} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/policies" component={Policies} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/privilege" component={Privilege} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/unauthorized" component={Unauthorized} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/registration" component={Registration} />
          <Route path={"/dashboard/:id"} component={DashboardPreview} />
          <Route path={"/verify-email/:id"} component={EmailVerification} />
          <Route path={"/reset-password/:token"} component={ChangePassword} />
          <Route exact path="/registration" component={Registration} />
          <Route path={"/mou-register"} component={MOURegister} />
          <Route path={"/register-member-tvl"} component={RegisterMemberTVL} />
          <Route
            path={"/register-member-platform"}
            component={RegisterMemberPlatform}
          />
          <Route path={"/register-member-mou"} component={RegisterMemberMOU} />
          <Route
            path={"/register-member-alliance-tvl"}
            component={RegisterMemberAllianceTVL}
          />
          <Route
            path={"/register-member-alliance-platform"}
            component={RegisterMemberAlliancePlatform}
          />
          <Route path={"/register-public-org"} component={RegisterPublicOrg} />
          <Route exact path={"/infographic"} component={Infographic} />
          <Route path={"/infographic/:id"} component={InfographicDetail} />
          <Route path={"/bookmark"} component={BookmarkPage} />

          <Route path={"/forbidden"} component={Forbidden} />
          <Route component={NotFound} />
        </Switch>

        <Footer />
        {cookiesPopupState ? (
          <CookiesPopup onClick={() => setCookiesPopupState(false)} />
        ) : (
          <></>
        )}
        <SessionPopup
          open={showSessionPopup}
          onClose={handleLogOut}
          onLogout={handleLogOut}
          message={sessionMessage}
        />
      </Router>
    </div>
  );
}

export default App;
