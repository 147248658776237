import React from 'react'
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPublicOrg } from '../../../services/data-connector'
import { PublicOrg } from '../../../interfaces/Register'
import { RootState } from '../../store';

interface PublicOrgState {
    publicOrgs: PublicOrg[],
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string
}

const initialState: PublicOrgState = {
    publicOrgs: [],
    status: 'idle',
    error: ''
}

export const fetchPublicOrgs = createAsyncThunk<PublicOrg[]>('publicOrg/fetchPublicOrgs', async (_, {rejectWithValue}) => {
    try {
        const { success, res } = await getPublicOrg()
        if (success) {
            const modifiedOrgs = res.orgList as PublicOrg[]
            modifiedOrgs.push({
                orgName: "อื่น ๆ",
                orgEmails: ['.go.th', '.or.th', '.ac.th', '.mi.th'],
                ministry: "-"
            })
            return modifiedOrgs
        } else {
            return rejectWithValue('Failed to fetch public organizations')
        }
    } catch (err) {
        return rejectWithValue((err as Error).message)
    }
})

export const publicOrgSlice = createSlice({
    name: 'publicOrg',
    initialState,
    reducers: {
        publicOrgsAdded(state, action) {
            state.publicOrgs = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPublicOrgs.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchPublicOrgs.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.publicOrgs = action.payload
            })
            .addCase(fetchPublicOrgs.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message ? action.error.message : ''
            })
    }
})

export const selectPublicOrgs = (state: RootState) => state.publicOrgs.publicOrgs;
export const selectPublicOrgStatus = (state: RootState) => state.publicOrgs.status;
export const selectPublicOrgError = (state: RootState) => state.publicOrgs.error;

export const { publicOrgsAdded } = publicOrgSlice.actions

export default publicOrgSlice.reducer
