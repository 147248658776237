import React, { useState, useEffect } from "react";
import {
  ThemeProvider,
  createTheme,
  TextField,
  Grid,
  Card,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  Radio,
  Box,
  FormControl,
  Autocomplete,
  InputAdornment,
  IconButton,
  Checkbox,
  Typography,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import style from "./RegisterPublicOrg.module.css";
import CustomContainer from "../../components/shared/CustomContainer/CustomContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { mergeThemes } from "../../theme/merge";
import { register_textbox_theme } from "../../theme/register";
import { autocomplete_theme, select_theme } from "../../theme/registration";
import { textbox_theme } from "../../theme/textbox";
import {
  registernew_theme,
  form_radio_gov_theme,
} from "../../theme/registernew";
import { typography_theme } from "../../theme/typography";
import TVLButton from "../../components/shared/TVLButton/TVLButton";
import {
  RegisterPublicOrgForm,
} from "../../validation/register";
import ErrorElement from "../../components/shared/ErrorElement/ErrorElement";
import ResponsePopUp from "../../components/shared/ResponsePopUp/ResponsePopUp";
import {
  registerPublicOrg,
  registerTvlMouOther,
} from "../../services/data-connector";
import { PublicOrg } from "../../interfaces/Register";
import TextRequired from "../../components/shared/TextRequired/TextRequired";
import AgreementPopUp from "../../components/shared/AgreementPopUp/AgreementPopUp";
import { joinWithCommaAnd } from "../../utils/register";
import { ReactComponent as ErrorSign } from "../../assets/register-member/Error.svg";
import { Location } from "history";
import { useSelector, useDispatch } from "react-redux";
import {
  selectPublicOrgs,
  selectPublicOrgStatus,
  fetchPublicOrgs,
} from "../../redux/features/publicOrg/publicOrgSlice";
import { AppDispatch } from "../../redux/store";
import { useForm, Controller } from "react-hook-form";

const OTHER_ORG_EMAIL_DOMAINS = [".go.th", ".or.th", ".ac.th", ".mi.th"];

const RegisterPublicOrg = ({
  location,
}: {
  location: Location<{ org: PublicOrg }>;
}) => {
  const mergedTheme = mergeThemes(
    register_textbox_theme,
    textbox_theme,
    registernew_theme,
    typography_theme,
    form_radio_gov_theme,
    select_theme
  );
  const theme = createTheme(mergedTheme);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchOrgName, setSearchOrgName] = useState<string>("");
  const [searchMinistry, setSearchMinistry] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPassword2, setShowPassword2] = useState<boolean>(false);
  const [errorForm, setErrorForm] = useState<string>("");
  const [responseStatus, setResponseStatus] = useState<"" | "email_partner">(
    ""
  );
  const [response, setResponse] = useState<React.ReactNode>(<></>);
  const [email, setEmail] = useState<string>("");
  const [ministries, setMinistries] = useState<string[]>([]);
  const [domain, setDomain] = useState<string[]>([]);
  const [agreementNo, setAgreementNo] = useState<number>(-1);
  const [ErrorSummary, setErrorSummary] = useState<React.ReactNode>(<></>);
  const publicOrgs = useSelector(selectPublicOrgs);
  const publicOrgStatus = useSelector(selectPublicOrgStatus);
  const dispatch = useDispatch<AppDispatch>();

  const year_list: string[] = [];
  for (let i = 2565; i > 2460; i--) {
    year_list.push(String(i));
  }

  const initialValues: RegisterPublicOrgForm = {
    firstName: "",
    lastName: "",
    ministry: "",
    organization: null,
    newOrg: "",
    position: "",
    email: "",
    phoneNo: "",
    gender: "",
    password: "",
    password2: "",
    yearOfBirth: null,
    checkBox: false,
  };


  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    watch,
    clearErrors,
    getValues,
  } = useForm<RegisterPublicOrgForm>({
    mode: "onTouched",
    defaultValues: initialValues,
  });

  const handleSubmitForm = async (values: RegisterPublicOrgForm) => {
    setIsLoading(true);
    let { phoneNo, ...payload } = values;
    if (phoneNo) {
      payload = values;
    }
    let formSubmission: (data: any) => Promise<{
      success: boolean;
      res: any;
    }> = registerPublicOrg;
    if (values.organization === "อื่น ๆ") {
      formSubmission = registerTvlMouOther;
    }

    let { success, res } = await formSubmission(payload);

    if (success) {
      setEmail(values.email);
      setResponseStatus("email_partner");
    } else if (res.detail === "The email has already been registered.") {
      setError("email", {
        message: "อีเมลนี้ลงทะเบียนไปแล้ว กรุณาใช้อีเมลอื่นในการสมัคร",
      });
      setErrorForm("อีเมลนี้ลงทะเบียนไปแล้ว กรุณาใช้อีเมลอื่นในการสมัคร");

      // scroll to email field
      let emailElement = document.querySelector(`input[name='email']`);
      emailElement?.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      setErrorForm(res.detail);
    }
    setIsLoading(false);
  };

  const response_element = (email?: string) => {
    if (responseStatus === "") {
      return <></>;
    } else if (responseStatus === "email_partner") {
      return (
        <ResponsePopUp
          open
          setOpen={() => {
            window.location.href = "/";
          }}
          status="email"
          title={`กรุณาตรวจสอบอีเมล`}
          email={email}
          text1="ข้อมูลการลงทะเบียนของท่านได้ถูกจัดเก็บเข้าระบบเรียบร้อยแล้ว"
          text2="กรุณาตรวจสอบอีเมลของท่านเพื่อทำการยืนยันตัวตน"
          button="กลับสู่หน้าหลัก"
          handleButton={() => {
            window.location.href = "/";
          }}
        />
      );
    }
  };

  useEffect(() => {
    if (publicOrgStatus !== "succeeded") {
      dispatch(fetchPublicOrgs())
        .unwrap()
        .then((publicOrgsTmp: PublicOrg[]) => {
          setMinistries([
            ...new Set(publicOrgsTmp.map((org: PublicOrg) => org.ministry)),
          ]);
        });
    } else {
      setMinistries([
        ...new Set(publicOrgs.map((org: PublicOrg) => org.ministry)),
      ]);
    }
    if (location.state?.org) {
      setValue("organization", location.state.org.orgName, {
        shouldValidate: true,
      });
      setValue("ministry", location.state.org.ministry, {
        shouldValidate: true,
      });
      setDomain(location.state.org.orgEmails);
    }
  }, [location]);

  useEffect(() => {
    setResponse(response_element(email));
  }, [responseStatus]);

  const email_validation = (domain: string[]) => (email: string) => {
    if (domain.length === 0) {
      return true;
    } else {
      let domainFound = false;

      domain.forEach((dom) => {
        if (email.endsWith(dom)) {
          domainFound = true;
        }
      });

      if (domainFound) {
        return true;
      } else {
        return (
          "กรุณากรอกอีเมลของหน่วยงานที่ท่านใช้ (ลงท้ายด้วย " +
          joinWithCommaAnd(domain) +
          ")"
        );
      }
    }
  };
  useEffect(() => {
    let errorElement = errorForm ? (
      <div style={{ marginBottom: "10px" }}>
        <ErrorElement errorMessage={errorForm} />
      </div>
    ) : (
      <></>
    );
    setErrorSummary(errorElement);
  }, [errorForm]);

  // for autocomplete
  const filter = createFilterOptions<string>();

  const ministry = watch("ministry");

  return (
    <CustomContainer>
      <AgreementPopUp open={agreementNo} setOpen={setAgreementNo} />
      {response}
      <ThemeProvider theme={theme}>
        <div className={style.title}>ลงทะเบียนสมาชิกจากภาครัฐ</div>
        <div className={style.subtitle}>กรอกข้อมูลลงทะเบียนเพิ่มเติม</div>
        <Box
          sx={{
            padding: { md: "0 30px", sm: "0 30px", xs: "0" },
            marginBottom: "100px",
          }}
        >
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={style.header}>ข้อมูลสำหรับเข้าใช้งาน</div>
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#ABABAB",
                  height: "1px",
                  margin: "0 10px",
                }}
              ></div>
            </div>

            <Grid
              container
              columnSpacing={5}
              rowSpacing={4}
              sx={{ marginBottom: { md: "70px", sm: "70px", xs: "50px" } }}
            >
              <Grid item md={6} sm={6} xs={12}>
                <div className={style.subheader}>ข้อมูลสำหรับเข้าใช้งาน</div>
                <Card sx={{ height: "90%" }}>
                  <div className={style.label}>
                    อีเมลหน่วยงาน/องค์กร
                    <TextRequired />
                  </div>
                  <TextField
                    {...register("email", {
                      required: "กรุณากรอกอีเมล",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "กรุณากรอกอีเมลให้ถูกต้อง",
                      },
                      validate: email_validation(domain),
                    })}
                    helperText={
                      !!errors.email ? (
                        <ErrorElement
                          errorMessage={errors.email.message || ""}
                        />
                      ) : (
                        <></>
                      )
                    }
                    placeholder="อีเมล"
                    fullWidth
                  />

                  <div className={style.label}>
                    <span>
                      ตั้งค่ารหัสผ่าน
                      <TextRequired />
                    </span>
                  </div>
                  <TextField
                    {...register("password", {
                      required: "กรุณากรอกรหัสผ่าน",
                      minLength: {
                        value: 8,
                        message: "รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร",
                      },
                    })}

                    helperText={
                      !!errors.password ? (
                        <ErrorElement
                          errorMessage={errors.password.message || ""}
                        />
                      ) : (
                        <></>
                      )
                    }
                    placeholder="รหัสผ่าน"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon sx={{ marginRight: "7px" }} />
                            ) : (
                              <VisibilityIcon sx={{ marginRight: "7px" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />

                  <div className={style.label}>
                    <span>
                      ยืนยันรหัสผ่าน
                      <TextRequired />
                    </span>
                  </div>
                  <TextField
                    {...register("password2", {
                      required: "กรุณายืนยันรหัสผ่าน",
                      validate: (value) => {
                        return value === getValues("password") || "รหัสผ่านไม่ตรงกัน";
                      },
                    })}
                    helperText={
                      !!errors.password2 ? (
                        <ErrorElement
                          errorMessage={errors.password2.message || ""}
                        />
                      ) : (
                        <></>
                      )
                    }
                    placeholder="รหัสผ่าน"
                    type={showPassword2 ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword2(!showPassword2)}
                            edge="end"
                          >
                            {showPassword2 ? (
                              <VisibilityOffIcon sx={{ marginRight: "7px" }} />
                            ) : (
                              <VisibilityIcon sx={{ marginRight: "7px" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </Card>
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <div className={style.subheader}>ข้อมูลหน่วยงาน/องค์กร</div>
                <Card sx={{ height: { md: "90%", sm: "90%", xs: "inherit" } }}>
                  <div className={style.label}>
                    กระทรวง
                    <TextRequired />
                  </div>

                  <ThemeProvider theme={createTheme(autocomplete_theme)}>
                    <Controller
                      control={control}
                      name="ministry"
                      rules={{
                        required: "กรุณากรอกกระทรวงที่ท่านสังกัด",
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Autocomplete
                          disablePortal

                          value={value}
                          options={ministries}
                          onBlur={onBlur}
                          onChange={(event: any, newValue: string | null) => {
                            onChange(newValue);
                            setValue("organization", null);
                            clearErrors("email");
                            setSearchOrgName("");
                          }}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="ministry"
                              value={searchMinistry}
                              onChange={(e) =>
                                setSearchMinistry(e.target.value)
                              }
                              placeholder="เลือกหน่วยงาน หรือองค์กร"
                              error={!!errors.ministry}
                              helperText={
                                !!errors.ministry ? (
                                  <ErrorElement
                                    errorMessage={errors.ministry.message || ""}
                                  />
                                ) : (
                                  <></>
                                )
                              }
                              sx={{
                                marginTop: "0",
                              }}
                            />
                          )}
                        />
                      )}
                    />

                    <div className={style.label}>
                      หน่วยงาน/องค์กร
                      <TextRequired />
                    </div>
                    <Controller
                      control={control}
                      name="organization"
                      rules={{
                        required: "กรุณากรอกองค์กรที่ท่านสังกัด",
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Autocomplete
                          disablePortal
                          value={value}
                          options={publicOrgs
                            .filter((orgTmp) =>
                              ministry ? orgTmp.ministry === ministry : true
                            )
                            .map((orgTmp) => orgTmp.orgName)
                            .concat(ministry !== "-" ? ["อื่น ๆ"] : [])}
                          onBlur={onBlur}
                          onChange={async (
                            event: any,
                            newValue: string | null
                          ) => {
                            onChange(newValue);
                            let matchOrg = publicOrgs.find(
                              ({ orgName }) => orgName === newValue
                            );
                            let domainTmp: string[] = [];
                            if (newValue == "อื่น ๆ") {
                              if (searchOrgName !== "อื่นๆ") {
                                // if user intends to select อื่น ๆ by searching other words, auto fill the option.
                                setValue("newOrg", searchOrgName);
                                setValue("ministry", "-");
                                domainTmp = OTHER_ORG_EMAIL_DOMAINS;
                              }
                              clearErrors("newOrg");
                            } else if (newValue === null) {
                              setSearchOrgName("");
                              domainTmp = [];
                              clearErrors("organization");
                              setValue("newOrg", "");
                            }
                            if (matchOrg) {
                              domainTmp = matchOrg.orgEmails;
                              if (!ministry) {
                                setValue("ministry", matchOrg.ministry);
                              }
                            }
                            clearErrors("email");
                            clearErrors("organization");
                            setDomain(domainTmp);
                            let email = getValues("email");
                            let email_error =
                              email_validation(domainTmp)(email);
                            if (typeof email_error === "string") {
                              setError("email", { message: email_error });
                            }
                          }}
                          fullWidth
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) =>
                              option.includes(inputValue)
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push("อื่น ๆ");
                            }

                            return filtered;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="organization"
                              value={searchOrgName}
                              onChange={(e) => setSearchOrgName(e.target.value)}
                              placeholder="เลือกหน่วยงาน หรือองค์กร"
                              error={!!errors.organization}
                              helperText={
                                !!errors.organization ? (
                                  <ErrorElement
                                    errorMessage={
                                      errors.organization.message || ""
                                    }
                                  />
                                ) : !value ? (
                                  <div style={{ marginTop: "10px" }}>
                                    <ThemeProvider theme={typography_theme}>
                                      <Typography
                                        variant="formHelperText"
                                        color="primary"
                                      >
                                        <ErrorSign
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            verticalAlign: "top",
                                            marginRight: "10px",
                                            fill: "#0182FA",
                                          }}
                                        />
                                        {
                                          "หากในรายการไม่มีหน่วยงาน/องค์กรของท่าน กรุณาเลือก “อื่น ๆ”"
                                        }
                                      </Typography>
                                    </ThemeProvider>
                                  </div>
                                ) : (
                                  <></>
                                )
                              }
                              sx={{
                                marginTop: "0",
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </ThemeProvider>

                  {getValues("organization") === "อื่น ๆ" ? (
                    <>
                      <Box height="20px"></Box>
                      <TextField
                        {...register("newOrg", {
                          required: "กรุณากรอกองค์กรที่ท่านสังกัด",
                        })}
                        error={!!errors.newOrg}

                        helperText={
                          !!errors.newOrg ? (
                            <ErrorElement
                              errorMessage={errors.newOrg.message || ""}
                            />
                          ) : (
                            <></>
                          )
                        }
                        placeholder="ชื่อหน่วยงาน/องค์กร"
                        fullWidth
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <div className={style.label}>
                    ตำแหน่ง
                    <TextRequired />
                  </div>
                  <TextField
                    {...register("position", {
                      required: "กรุณากรอกตำแหน่งในองค์กรของท่าน",
                    })}
                    error={!!errors.position}

                    helperText={
                      !!errors.position ? (
                        <ErrorElement
                          errorMessage={errors.position.message || ""}
                        />
                      ) : (
                        <></>
                      )
                    }
                    placeholder="ตำแหน่ง"
                    fullWidth
                  />
                </Card>
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "25px",
                marginBottom: "10px",
              }}
            >
              <div className={style.header}>ข้อมูลผู้สมัครใช้งาน</div>
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#ABABAB",
                  height: "1px",
                  margin: "0 10px",
                }}
              ></div>
            </div>

            <div className={style.subheader}>รายละเอียดผู้สมัครใช้งาน</div>
            <Card>
              <Grid container columnSpacing={9}>
                <Grid item md={6} sm={6} xs={12}>
                  <div className={style.label}>
                    ชื่อ
                    <TextRequired />
                  </div>
                  <TextField
                    {...register("firstName", {
                      required: "กรุณากรอกชื่อของท่าน",
                      pattern: {
                        value: /^[a-zA-Z\u0E00-\u0E7F]+$/,
                        message: "กรุณากรอกเฉพาะตัวอักษรเท่านั้น",
                      },
                    })}
                    error={!!errors.firstName}


                    helperText={
                      !!errors.firstName ? (
                        <ErrorElement
                          errorMessage={errors.firstName.message || ""}
                        />
                      ) : (
                        <></>
                      )
                    }
                    placeholder="ชื่อ"
                    fullWidth
                  />

                  <div className={style.label}>
                    นามสกุล
                    <TextRequired />
                  </div>
                  <TextField
                    {...register("lastName", {
                      required: "กรุณากรอกนามสกุลของท่าน",
                      pattern: {
                        value: /^[a-zA-Z\u0E00-\u0E7F]+$/,
                        message: "กรุณากรอกเฉพาะตัวอักษรเท่านั้น",
                      },
                    })}
                    error={!!errors.lastName}


                    helperText={
                      !!errors.lastName ? (
                        <ErrorElement
                          errorMessage={errors.lastName.message || ""}
                        />
                      ) : (
                        <></>
                      )
                    }
                    placeholder="นามสกุล"
                    fullWidth
                  />

                  <Box
                    display={{ md: "flex", sm: "flex", xs: "block" }}
                    justifyContent={{ md: "flex-start", sm: "flex-start" }}
                    margin={{
                      xs: "15px 0",
                      sm: "15px 0 0 0",
                      md: "15px 0 0 0",
                    }}
                  >
                    <Box
                      className={style.label}
                      sx={{ margin: "9px 10px auto 0 !important" }}
                    >
                      เพศ
                      <TextRequired />
                    </Box>
                    <RadioGroup row>
                      <FormControlLabel
                        {...register("gender", {
                          required: "กรุณาระบุเพศของท่าน",
                        })}
                        control={<Radio disableRipple />}
                        value="male"
                        label="ชาย"

                      />
                      <FormControlLabel
                        {...register("gender", {
                          required: "กรุณาระบุเพศของท่าน",
                        })}
                        control={<Radio disableRipple />}
                        value="female"
                        label="หญิง"

                      />

                      <FormControlLabel
                        {...register("gender", {
                          required: "กรุณาระบุเพศของท่าน",
                        })}
                        control={<Radio disableRipple />}
                        value="others"
                        label="ไม่ระบุ"

                      />
                    </RadioGroup>
                  </Box>

                  {!!errors.gender ? (
                    <FormHelperText
                      error
                      sx={{
                        paddingLeft: "15px",
                        marginTop: "-10px",
                        marginBottom: "10px !important",
                      }}
                    >
                      {
                        <ErrorElement
                          errorMessage={errors.gender.message || ""}
                        />
                      }
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                  container
                  direction="column"
                  display="flex"
                >
                  <div className={style.label}>เบอร์โทรศัพท์มือถือ</div>
                  <TextField
                    {...register("phoneNo", {
                      pattern: {
                        value: /(\+66|0)((2)(-?\d{3}\-?\d{4})|(32|33|34|35|36|37|38|39|42|43|44|45|53|54|55|56|73|74|75|76|77)(-?\d{3}\-?\d{3})|([689])(\d{1}\-?\d{3}\-?\d{4}))/,
                        message: "กรุณากรอกเบอร์โทรศัพท์มือถือที่ใช้งานได้ในประเทศ (ตัวอย่างเช่น 0987654321, 055-123-456, +662-233-4455)",
                      },
                    })}
                    error={!!errors.phoneNo}


                    helperText={
                      !!errors.phoneNo ? (
                        <ErrorElement
                          errorMessage={errors.phoneNo.message || ""}
                        />
                      ) : (
                        <></>
                      )
                    }
                    placeholder="เบอร์โทรศัพท์มือถือ"
                    fullWidth
                  />

                  <div className={style.label}>
                    ปีเกิด
                    <TextRequired />
                  </div>
                  <Controller
                    control={control}
                    name="yearOfBirth"
                    rules={{
                      required: "กรุณากรอกปีเกิด",
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Autocomplete
                        disablePortal

                        id="combo-box-demo"
                        value={value}
                        options={year_list}
                        onChange={(event: any, newValue: string | null) => {
                          onChange(newValue);
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="ปีเกิด (พ.ศ.)"
                            name="alliance"
                            error={!!errors.yearOfBirth}
                            helperText={
                              !!errors.yearOfBirth ? (
                                <ErrorElement
                                  errorMessage={
                                    errors.yearOfBirth.message || ""
                                  }
                                />
                              ) : (
                                <></>
                              )
                            }
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline legend": {
                                marginLeft: "10px",
                              },
                            }}
                          />
                        )}
                        sx={{
                          ".MuiInputBase-root": {
                            paddingLeft: "24px",
                            paddingTop: "6px",
                            paddingBottom: "6px",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Card>

            <FormControl sx={{ left: "0", right: "0", margin: "35px auto" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    {...register("checkBox", {
                      validate: (value) => {
                        if (!value) {
                          return "กรุณายอมรับข้อตกลง";
                        }
                        return true;
                      },
                    })}
                  />
                }
                label={
                  <Box textAlign="left">
                    ท่านได้ทำการอ่าน และยอมรับ
                    <Typography
                      variant="ahref"
                      sx={{ margin: "0 5px", fontWeight: "400" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setAgreementNo(0);
                      }}
                    >
                      Privacy Policy
                    </Typography>
                    และ
                    <Typography
                      variant="ahref"
                      sx={{ margin: "0 5px", fontWeight: "400" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setAgreementNo(1);
                      }}
                    >
                      Terms & Conditions
                    </Typography>{" "}
                    แล้ว{" "}
                  </Box>
                }
                sx={{
                  alignItems: "flex-start",
                  "& .MuiCheckbox-root": {
                    paddingTop: "0",
                  },
                }}
              />
              {!!errors.checkBox && (
                <FormHelperText
                  error
                >
                <ErrorElement
                  errorMessage={errors.checkBox?.message || ""}
                  />
              </FormHelperText>
              )}
            </FormControl>


            <div>
              {ErrorSummary}

              <TVLButton
                type="submit"
                size="large"
                isLoading={isLoading}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault();
                }}
              >
                ลงทะเบียน
              </TVLButton>
            </div>
          </form>
        </Box>
      </ThemeProvider>
    </CustomContainer>
  );
};

export default RegisterPublicOrg;
