import React, { useState, memo } from 'react'
import {
    SwipeableDrawer,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Divider,
    ThemeProvider
} from '@mui/material'
import CloseIcon from "@mui/icons-material/Close"
import { getUserAccount } from '../../../utils/login'
import proavatar from '../../../assets/dashboard/proavatar.svg'
import { ReactComponent as Crown } from '../../../assets/navbar/crown_blank.svg'
import { useHistory } from 'react-router-dom'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import Cookies from 'js-cookie'
import { listItemTheme } from '../../../theme/drawerTheme'
import { useSelector } from 'react-redux'
import { selectOrgType } from '../../../redux/features/authentication/authenticationSlice'

interface DrawerRightProps {
    openDrawer: string,
    setOpenDrawer: React.Dispatch<string>,
}

const DrawerRight = memo(({
    openDrawer,
    setOpenDrawer
}: DrawerRightProps) => {
    const [user, setUser] = useState(getUserAccount())
    const history = useHistory()
    const orgType = useSelector(selectOrgType)
    const isPending = user.getOrgPending().length > 0 && user.getApprovedOrg!().length == 0
    const isAdmin = user.getIsAdmin()
    const isPrivilege = user.getIsPrivileged()
    const isEmailVerified = user.getIsEmailVerified()

    const handleLogOut = () => {
        Cookies.remove("token")
        setUser(getUserAccount())
        if(window.location.search){
            // remove ?from=register
            if(window.location.search.includes('from')){
                window.location.href = window.location.pathname
            }else{
                window.location.reload()
            }
        } else {
            window.location.reload()
        }
        setOpenDrawer("")
    }

  return (
    <SwipeableDrawer
    anchor="right"
    open={openDrawer === "right"}
    onOpen={() => setOpenDrawer("right")}
    onClose={() => {
        setOpenDrawer("")
    }}>
        <Box width={{sm:'530px', xs:'100vw'}} paddingX='8px'>
            <Box height="64px" display='flex' justifyContent='flex-start' padding='14px'>
                <CloseIcon
                    sx={{
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                        padding:'5px', 
                        borderRadius: '30px',
                        color: '#194B7E',
                        ":hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                        }
                    }}
                    onClick={() => {
                        setOpenDrawer("false")
                    }}
                />
            </Box>

            <Box display={{md:'none', sm:'none', xs: 'block'}}>
                    <Divider />
                    
                </Box>
            <ThemeProvider theme={listItemTheme}>
                <List>
                    <ListItem>
                        <ListItemAvatar sx={{position:"relative", minWidth:'0', marginRight:"10px"}}>
                            {
                                user.getIsLoggedIn() &&
                                user.hasAvatarPicture() ? (
                                    <img
                                        src={user.getAvatarPicture!()}
                                        style={{ borderRadius: "50%", width: "35px", verticalAlign:"middle" }}
                                    />
                                ) : (
                                    <Avatar/>
                                )
                                
                            }
                            {
                                user.getIsLoggedIn() &&
                                user.getIsPrivileged() ? 
                                <img src={proavatar} style={{width:'13px', height:'13px', position:'absolute', bottom:'0px', right:'0px'}}/>
                                :<></>
                            }
                        </ListItemAvatar>
                        <ListItemText
                        primary={user.getIsLoggedIn() ? user.getFirstName!() + " " + user.getLastName!() : "Travel Link"}
                        secondary={user.getIsLoggedIn() ? user.getRoleDescription!(orgType) : ""}
                        secondaryTypographyProps={ isPending ? { color: '#EF6C00' }: {}}
                        />
                    </ListItem>

                    <Divider/>

                    {
                        isEmailVerified &&  isPrivilege ?
                        <ListItem
                        onClick={() => {
                            history.push("/privilege")
                            window.scrollTo(0,0)
                            setOpenDrawer("")
                        }}>
                            <ListItemAvatar>
                                <Crown style={{stroke:'#515151'}}/>
                            </ListItemAvatar>
                            <ListItemText primary="สิทธิพิเศษ" />
                        </ListItem>
                        :
                        <></>
                    }
                    

                    <ListItem
                    onClick={() => {
                        window.location.href = "/bookmark"
                        setOpenDrawer("")
                    }}>
                        <ListItemAvatar>
                            <BookmarkBorderIcon sx={{color: '#515151'}} />
                        </ListItemAvatar>
                        <ListItemText primary="รายการที่ติดตาม" />
                    </ListItem>
                    {
                        isEmailVerified && !isPending && isAdmin ?
                        <a
                            href="/api/user/user/to-admin"
                            target="_blank">
                            <ListItem>
                                
                                    <ListItemAvatar>
                                        <ManageAccountsOutlinedIcon sx={{color: '#515151'}}/>
                                    </ListItemAvatar>
                                    <ListItemText primary="เข้าสู่ระบบแอดมิน" />
                            </ListItem>
                        </a>
                        :
                        <></>

                    }
                    

                    <Divider />

                    <ListItem
                    onClick={() => {
                        history.push('/contact')
                        window.scrollTo(0,0)
                        setOpenDrawer("")
                    }}>
                        <ListItemAvatar>
                            <HelpOutlineIcon sx={{color: '#515151'}} />
                        </ListItemAvatar>
                        <ListItemText primary="ติดต่อเรา" sx={{color: '#515151'}}/>
                    </ListItem>


                    <ListItem
                    onClick={handleLogOut}>
                        <ListItemAvatar>
                            <LogoutIcon sx={{color: '#515151'}}/>
                        </ListItemAvatar>
                        <ListItemText primary="ออกจากระบบ" sx={{color: '#515151'}} />
                    </ListItem>
                </List>

            </ThemeProvider>
        </Box>
    </SwipeableDrawer>
  )
}
)

export default DrawerRight