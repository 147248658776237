import axios from 'axios';
import { usePreviousDistinct } from 'react-use';

export async function getDefaultRequestHeaders() {
    let header = {
        'Cache-Control': `no-cache`,
        'Pragma': `no-cache`,
        'Expires': `0`,
        'Access-Control-Allow-Origin': `*`
    };

    return header;
}

export async function createEmail(email) {
    try {     

        const res = await axios.post("api/email/email/download-example", email,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        // return { success: false, res: err.response };
        return { success: false };
    }
}



export async function createInquiryEmail(data) {
    try {
      
        const res = await axios.post("/api/email/email/inquiry-contact", data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        // return { success: false, res: err.response };
        return { success: false };
    }
}

export async function getDashboardMetadata(token) {
    try {

        var headers = await getDefaultRequestHeaders(true)
        headers['Authorization'] = "Bearer " + token

        const res = await axios.get('/api/dashboard/dashboard/role-query', {
            headers: headers
        })
        // console.log(res.status)
        return { success: true, res: res.data, status: res.status };

    }
    catch (err) {
        // console.log(err);
        // return { success: false, res: err.response };
        return { success: false, res: err.response?.data, status: err.response?.status };
    }
}

export async function getAccessToken(refreshToken) {
    try {

        var headers = await getDefaultRequestHeaders(true)
        headers['Authorization'] = "Bearer " + refreshToken

        const res = await axios.get('/api/user/user/request-access-token', {
            headers: headers
        })
        // console.log(res.status)
        return { success: true, res: res.data, status: res.status };

    }
    catch (err) {
        return { success: false, res: err.response?.data, status: err.response?.status };
    }
    
}


export async function getOrg() {
    try {
        var headers = await getDefaultRequestHeaders(true)
        const res = await axios.get('/api/user/user/get-org', {
            headers: headers
        })
        // console.log(res)
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        return { success: false };
    }
}

export async function getPublicOrg() {
    try {
        var headers = await getDefaultRequestHeaders(true)
        const res = await axios.get('/api/user/user/get-public-orgs', {
            headers: headers
        })
        // console.log(res)
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        return { success: false };
    }
}

export async function getQuestionaire() {
    try {
        var headers = await getDefaultRequestHeaders(true)
        const res = await axios.get('/api/user/user/get-questionaire', {
            headers: headers
        })
        // console.log(res)
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        return { success: false };
    }
}

export async function register(data) {
    try {
   
        const res = await axios.post("/api/user/user/register", data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        return { success: false, res: err.response?.data };
    }
}

export async function dashboard_details(dashboard_id,accessToken) {
    try {
        var headers = await getDefaultRequestHeaders()
        headers['Authorization'] = "Bearer " + accessToken
        const res = await axios.get("/api/dashboard/dashboard/dashboard-details", 
            {
                params: {dashboard_id: dashboard_id},
                headers: headers
            });
        return { status: res.status, success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        return { status: err.response?.status, success: false, res: err.response?.data };
    }
}


export async function verify_email(token) {
    try {
   
        const res = await axios.get("/api/user/user/verify_email/"+token,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        return { success: false, res: err.response?.data };
    }
}

export async function login(data) {
    try {
   
        const res = await axios.post("/api/user/user/login", data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        return { success: false, res: err.response?.data };
    }
}

export async function forgot_password(data) {
    try {
   
        const res = await axios.post("/api/user/user/forgot-password", data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        return { success: false, res: err.response?.data };
    }
}

export async function reset_password(token, data={}) {
    if(JSON.stringify(data) === '{}'){
        try {
   
            const res = await axios.get("/api/user/user/reset-password/" + token, data,
                {
                    headers: await getDefaultRequestHeaders()
                });
            return { success: true, res: res.data };
    
        }
        catch (err) {
            return { success: false, res: err.response?.data };
        }
    }
    try {
   
        const res = await axios.post("/api/user/user/reset-password/" + token, data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        return { success: false, res: err.response?.data };
    }
}

export async function toAdmin(refreshToken) {
    try {

        var headers = await getDefaultRequestHeaders(true)
        headers['Authorization'] = "Bearer " + refreshToken

        const res = await axios.get('/api/user/user/to-admin', {
            headers: headers
        })
        // console.log(res.status)
        return { success: true, res: res.data, status: res.status };

    }
    catch (err) {
        return { success: false, res: err.response?.data, status: err.response?.status };
    }
    
}

export async function extendUuid(id, uuid, accessToken) {
    try {

        var headers = await getDefaultRequestHeaders(true)
        headers['Authorization'] = "Bearer " + accessToken

        const res = await axios.post('/api/dashboard/dashboard/extend-uuid',{
            uuid:uuid
        } ,{
            params: {pageId: id},
            headers: headers
        })
        // console.log(res.status)
        return { success: true, res: res.data, status: res.status };

    }
    catch (err) {
        return { success: false, res: err.response?.data };
    }
    
}

export async function registerTvlUser(data) {
    try {
   
        const res = await axios.post("/api/user/user/register-tvl-user", data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        return { success: false, res: err.response?.data };
    }
}

export async function isEmailRegistered(data) {
    try {
   
        const res = await axios.post("/api/user/user/is-email-registered", data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        return { success: false, res: err.response?.data };
    }
}

export async function registerPlatformUser(data) {
    try {
   
        const res = await axios.post("/api/user/user/register-platform-user", data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        return { success: false, res: err.response?.data };
    }
}


export async function registerTvlMou(data) {
    try {
   
        const res = await axios.post("/api/user/user/register-tvl-mou", data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        return { success: false, res: err.response?.data };
    }
}

export async function registerPublicOrg(data) {
    try {
        const res = await axios.post("/api/user/user/register-tvl-public-org", data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };
    }
    catch (err) {
        console.log(err);
        return { success: false, res: err.response?.data };
    }
}

export async function registerTvlMouOther(data) {
    try {
   
        const res = await axios.post("/api/user/user/register-tvl-mou-other", data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        return { success: false, res: err.response?.data };
    }
}

export async function registerTvlAlliance(data) {
    try {
   
        const res = await axios.post("/api/user/user/register-tvl-alliance", data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        // return { success: false, res: err.response };
        return { success: false, res: err.response?.data };
    }
}

export async function registerPlatformAlliance(data) {
    try {
   
        const res = await axios.post("/api/user/user/register-platform-alliance", data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        // return { success: false, res: err.response };
        return { success: false, res: err.response?.data };
    }
}

export async function mouRegister(data) {
    try {
   
        const res = await axios.post("/api/user/user/organization-registration", data,
            {
                headers: await getDefaultRequestHeaders()
            });
        return { success: true, res: res.data };

    }
    catch (err) {
        console.log(err);
        // return { success: false, res: err.response };
        return { success: false, res: err.response?.data };
    }
}

export async function getInfographicMetadata(token) {
    try {

        var headers = await getDefaultRequestHeaders(true)
        headers['Authorization'] = "Bearer " + token


        const res = await axios.get('/api/infographic/infographic/get-infographic', {
            headers: headers
        })
        // console.log(res.status)
        return { success: true, res: res.data, status: res.status };

    }
    catch (err) {
        // console.log(err);
        // return { success: false, res: err.response };
        return { success: false, res: err.response?.data, status: err.response?.status };
    }
}

export async function getInfographicMetadataDetail(accessToken, infographicId) {
    try {
        var headers = await getDefaultRequestHeaders(true)
        headers['Authorization'] = "Bearer " + accessToken

        const res = await axios.get('/api/infographic/infographic/get-infographic-detail', {
            params:{infographicId: infographicId},
            headers: headers
        })
        // console.log(res.status)
        return { success: true, res: res.data, status: res.status };

    }
    catch (err) {
        // console.log(err);
        // return { success: false, res: err.response };
        return { success: false, res: err.response?.data, status: err.response?.status };
    }
}

export async function bookmarkInfographic(accessToken, infographicId) {
    try {
        var headers = await getDefaultRequestHeaders(true)
        headers['Authorization'] = "Bearer " + accessToken

        const res = await axios.post('/api/infographic/infographic/bookmark', 
        {
            infographicId: infographicId
        },{
            headers: headers
        })
        // console.log(res.status)
        return { success: true, res: res.data, status: res.status };

    }
    catch (err) {
        // console.log(err);
        // return { success: false, res: err.response };
        return { success: false, res: err.response?.data, status: err.response?.status };
    }
}

export async function unbookmarkInfographic(accessToken, infographicId) {
    try {
        var headers = await getDefaultRequestHeaders(true)
        headers['Authorization'] = "Bearer " + accessToken

        const res = await axios.post('/api/infographic/infographic/unbookmark', 
        {
            infographicId: infographicId
        },{
            headers: headers
        })
        // console.log(res.status)
        return { success: true, res: res.data, status: res.status };

    }
    catch (err) {
        // console.log(err);
        // return { success: false, res: err.response };
        return { success: false, res: err.response?.data, status: err.response?.status };
    }
}

export async function bookmarkDashboard(accessToken, pageId) {
    try {
        var headers = await getDefaultRequestHeaders(true)
        headers['Authorization'] = "Bearer " + accessToken

        const res = await axios.post('/api/dashboard/dashboard/bookmark', 
        {
            pageId: pageId
        },{
            headers: headers
        })
        // console.log(res.status)
        return { success: true, res: res.data, status: res.status };

    }
    catch (err) {
        // console.log(err);
        // return { success: false, res: err.response };
        return { success: false, res: err.response?.data, status: err.response?.status };
    }
}

export async function unbookmarkDashboard(accessToken, pageId) {
    try {
        var headers = await getDefaultRequestHeaders(true)
        headers['Authorization'] = "Bearer " + accessToken

        const res = await axios.post('/api/dashboard/dashboard/unbookmark', 
        {
            pageId: pageId
        },{
            headers: headers
        })
        // console.log(res.status)
        return { success: true, res: res.data, status: res.status };

    }
    catch (err) {
        // console.log(err);
        // return { success: false, res: err.response };
        return { success: false, res: err.response?.data, status: err.response?.status };
    }
}

export async function getAlertMessage() {
    try {
        var headers = await getDefaultRequestHeaders(true)

        const res = await axios.get('/api/user/user/alert-messages', 
        {
            headers: headers
        })
        // console.log(res.status)
        return { success: true, res: res.data, status: res.status };

    }
    catch (err) {
        // console.log(err);
        // return { success: false, res: err.response };
        return { success: false, res: err.response?.data, status: err.response?.status };
    }
}

export async function getOrgType(accessToken) {
    try {
        var headers = await getDefaultRequestHeaders(true)
        headers['Authorization'] = "Bearer " + accessToken
        const res = await axios.get('/api/user/user/org-type', {
            headers: headers

        })
        return { success: true, res: res.data, status: res.status };
    }
    catch (err) {
        return { success: false, res: err.response?.data, status: err.response?.status };
    }
}
