import {
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Box,
  ThemeProvider,
  createTheme,
  TableContainer,
  CircularProgress,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { logo } from "../../../assets/navbar";
import TVLButton from "../../../components/shared/TVLButton/TVLButton";
import { textbox_theme } from "../../../theme/textbox";
import { useSelector } from "react-redux";
import {
  selectPublicOrgs,
  selectPublicOrgStatus,
} from "../../../redux/features/publicOrg/publicOrgSlice";
import SearchIcon from "@mui/icons-material/Search";
import {
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { register_textbox_theme } from "../../../theme/register";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
  RowSelectionState,
  Row,
  SortingState,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import debounce from "lodash/debounce";
import { PublicOrg } from "../../../interfaces/Register";
import _ from "lodash";
import { useHistory } from "react-router-dom";

interface PublicOrgPopUpProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
  },
});

const StyledTable = styled(Table)({
  tableLayout: "fixed",
  position: "relative",
  width: "100%",
  "& .MuiTableCell-root": {
    fontFamily: "Kanit",
    padding: "8px",
  },
  "& .MuiTableBody-root": {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "block",
    overflow: "visible",
  },
  "& .MuiTableBody-root .MuiTableRow-root": {
    position: "absolute",
    top: 0,
    left: 0,
  },
});

const StyledTableHead = styled(TableHead)({
  "& .MuiTableCell-root": {
    fontFamily: "Kanit",
    fontWeight: "500",
    fontSize: "20px",
    color: "#073856",
    backgroundColor: "#F1F7FD",
    padding: "8px",
  },
});

interface ModPublicOrg extends Omit<PublicOrg, "orgEmails"> {
  orgEmails: string;
}

const emailFilterFn = (
  row: Row<ModPublicOrg>,
  id: string,
  filterValue: string
) => {
  const email_pattern_regex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (email_pattern_regex.test(filterValue)) {
    const domains = filterValue.split("@")[1];
    return row.original.orgEmails.includes(domains);
  }
  return row.original.orgEmails.includes(filterValue);
};

const PublicOrgPopUp: React.FC<PublicOrgPopUpProps> = ({ open, setOpen }) => {
  const publicOrgs = useSelector(selectPublicOrgs);
  const publicOrgStatus = useSelector(selectPublicOrgStatus);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sorting, setSorting] = useState<SortingState>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<RowSelectionState>({});
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const modifiedPublicOrgs = useMemo(
    () =>
      publicOrgs.map((org) => ({
        ...org,
        orgEmails: org.orgEmails.join(", ") || "-",
      })),
    [publicOrgs]
  );
  const [otherOrg, setOtherOrg] = useState<Row<ModPublicOrg> | null>(null);
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);

  const columns = useMemo(
    () => [
      {
        header: "ชื่อกระทรวง",
        accessorKey: "ministry",
        size: 280,
        enableSorting: true,
      },
      {
        header: "ชื่อหน่วยงาน",
        accessorKey: "orgName",
        size: 300,
        enableSorting: true,
      },
      {
        header: "อีเมลหน่วยงาน",
        accessorKey: "orgEmails",
        size: 250,
        enableSorting: false,
      },
    ],
    []
  );

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setDebouncedSearchTerm(value);
    }, 300),
    []
  );

  const table = useReactTable({
    data: modifiedPublicOrgs,
    columns,
    state: {
      globalFilter: debouncedSearchTerm || "",
      rowSelection: selectedRowIds,
      sorting: sorting,
    },
    globalFilterFn: (row, id, filterValue) => {
      if (id === "orgEmails") {
        return emailFilterFn(row, id, filterValue);
      }
      return row.original[id as keyof ModPublicOrg]!.includes(filterValue);
    },
    onGlobalFilterChange: setDebouncedSearchTerm,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiRowSelection: false,
    onRowSelectionChange: setSelectedRowIds,
    onSortingChange: setSorting,
  });

  const rowModel = table.getRowModel();
  let { rows } = rowModel;

  // First, move the rows manipulation into a useMemo
  const manipulatedRows = useMemo(() => {
    let resultRows = [...rowModel.rows];
    const otherOrgTmp = resultRows.find(
      (row) => row.original.orgName === "อื่น ๆ"
    );
    if (otherOrgTmp) {
      resultRows = resultRows.filter(
        (row) => row.original.orgName !== "อื่น ๆ"
      );
      resultRows.push(otherOrgTmp);
    } else if (resultRows.length === 0 && otherOrg) {
      resultRows = [otherOrg];
    }

    return resultRows;
  }, [rows, otherOrg, debouncedSearchTerm]);

  // Update the virtualizer to use manipulatedRows
  const rowVirtualizer = useVirtualizer({
    count: manipulatedRows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 58,
    overscan: 10,
    paddingStart: 5,
  });

  // Update handleKeyDown to use manipulatedRows
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        event.preventDefault();
        setFocusedIndex((prevIndex) => {
          const newIndex =
            event.key === "ArrowDown"
              ? Math.min(prevIndex + 1, manipulatedRows.length - 1)
              : Math.max(prevIndex - 1, 0);

          // Select the focused row
          const row = manipulatedRows[newIndex];
          if (row) {
            setSelectedRowIds({ [row.id]: true });

            // Scroll into view if necessary
            const rowTop = newIndex * 58;
            const rowBottom = rowTop + 58;
            const containerHeight =
              tableContainerRef.current?.clientHeight || 0;
            const scrollTop = tableContainerRef.current?.scrollTop || 0;

            if (rowTop < scrollTop) {
              // When scrolling up, add some padding to show more context
              tableContainerRef.current?.scrollTo({
                top: Math.max(0, rowTop - 58), // Show one row above for context
              });
            } else if (rowBottom > scrollTop + containerHeight) {
              // When scrolling down, add some padding to show more context
              tableContainerRef.current?.scrollTo({
                top: rowBottom - containerHeight + 58, // Show one row below for context
              });
            }
          }
          return newIndex;
        });
      }
    },
    [manipulatedRows]
  );

  const history = useHistory();
  const handleSelectOrg = (org: PublicOrg) => {
    setOpen(false);
    history.push("/register-public-org", { org: org });
  };
  useEffect(() => {
    if (open) {
      setIsLoading(true);
      setSorting([{ id: "orgName", desc: false }]);
      if (publicOrgStatus === "succeeded") {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    } else {
      setSelectedRowIds({});
      setSearchTerm("");
      setDebouncedSearchTerm("");
    }
  }, [open, debouncedSearchTerm, publicOrgStatus]);

  useEffect(() => {
    // set otherOrg to the first row if otherOrg is not set
    if (!otherOrg) {
      const otherOrgTmp = rows.find((row) => row.original.orgName === "อื่น ๆ");
      if (otherOrgTmp) {
        setOtherOrg(otherOrgTmp);
      }
    }
  }, [publicOrgs]);

  // Add effect to handle keyboard events
  useEffect(() => {
    if (open) {
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    }
  }, [open, handleKeyDown]);

  // Reset focused index when dialog closes
  useEffect(() => {
    if (!open) {
      setFocusedIndex(-1);
    }
  }, [open]);

  return (
    <StyledDialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ textAlign: "center", pt: 3, pb: 0 }}>
        <img src={logo} alt="logo" style={{ height: "31px" }} />
        <Typography
          variant="h2"
          sx={{
            margin: 0,
            fontFamily: "Kanit",
            fontWeight: "600",
            fontSize: "32px",
          }}
        >
          กรุณาเลือกหน่วยงานที่ต้องการสมัคร
        </Typography>
        <Typography
          variant="body2"
          sx={{ margin: "8px 0 0", color: "#666", fontFamily: "Kanit" }}
        >
          หากหาหน่วยงานของท่านไม่เจอ กรุณาเลือก "อื่น ๆ"
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ marginTop: "20px" }}>
        <ThemeProvider
          theme={createTheme(textbox_theme, register_textbox_theme)}
        >
          <TextField
            placeholder="ค้นหาหน่วยงานด้วยชื่อกระทรวง ชื่อหน่วยงาน หรืออีเมลหน่วยงาน"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              debouncedSetSearch(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
            fullWidth
            sx={{ margin: "10px" }}
          />
        </ThemeProvider>

        <TableContainer
          id="table-container"
          ref={tableContainerRef}
          sx={{
            border: "1px solid #E0E0E0",
            height: "300px",
            overflow: "auto",
            position: "relative",
            display: "block",
            // Safari-specific scrollbar styles
            WebkitOverflowScrolling: "touch", // Enables momentum scrolling in Safari
            "&::-webkit-scrollbar": {
              width: "8px",
              backgroundColor: "#F5F5F5",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888888", // Darker color for better visibility
              borderRadius: "4px",
              border: "2px solid #F5F5F5", // Add border to make thumb more visible
              minHeight: "30px", // Ensure minimum height for the thumb
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#F5F5F5",
              borderRadius: "4px",
            },
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <StyledTable
              sx={{ 
                height: `${rowVirtualizer.getTotalSize()}px`,
                position: "relative",
                width: "100%"
              }}
              stickyHeader
            >
              <StyledTableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell
                        key={header.id}
                        style={{
                          width: header.column.getSize(),
                          minWidth: header.column.getSize(),
                          height: "30px",
                        }}
                      >
                        {header.column.getCanSort() ? (
                          <TableSortLabel
                            active={!!header.column.getIsSorted()}
                            direction={
                              header.column.getIsSorted() === "desc"
                                ? "desc"
                                : "asc"
                            }
                            onClick={() => header.column.toggleSorting()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </TableSortLabel>
                        ) : (
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </StyledTableHead>
              <TableBody
                style={{
                  height: `${rowVirtualizer.getTotalSize()}px`,
                  position: "relative",
                  width: "100%",
                  display: "block"
                }}
              >
                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                  const row = manipulatedRows[virtualRow.index];
                  const isSelected = selectedRowIds[row.id];
                  return (
                    <TableRow
                      key={row.id}
                      hover
                      style={{
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start - 5}px)`,
                        cursor: "pointer",
                        backgroundColor: isSelected ? "#F1F7FD" : "transparent",
                        border: isSelected ? "1px solid #0182FA" : "none",
                        paddingBottom: isSelected
                          ? `${virtualRow.size}px`
                          : "none",
                      }}
                      onClick={() => {
                        row.toggleSelected();
                        setFocusedIndex(virtualRow.index);
                      }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          style={{
                            height: `${virtualRow.size}px`,
                            width: cell.column.getSize(),
                            minWidth: cell.column.getSize(),
                            borderBottom: isSelected
                              ? "none"
                              : "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          <Box
                            sx={{
                              height: "42px",
                              WebkitLineClamp: "2",
                              whiteSpace: "normal",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitAlignContent: "center",
                              overflow: "hidden",
                            }}
                            title={cell.getContext().getValue() as string}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </StyledTable>
          )}
        </TableContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "12px",
            marginTop: "20px",
          }}
        >
          <TVLButton
            size="small"
            color="primary"
            disabled={Object.keys(selectedRowIds).length === 0}
            onClick={() => {
              const selectedOrg = publicOrgs.find(
                (org, index) =>
                  Object.keys(selectedRowIds)[0] === index.toString()
              );
              if (selectedOrg) {
                handleSelectOrg(selectedOrg);
              } else {
                handleSelectOrg(publicOrgs[0]);
              }
            }}
          >
            ยืนยัน
          </TVLButton>
          <TVLButton
            size="small"
            color="tertiary"
            onClick={() => setOpen(false)}
          >
            ยกเลิก
          </TVLButton>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default PublicOrgPopUp;
